import { TYPE } from "vue-toastification";
import { apiCall, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";
import { router } from "@/core/router";

export async function getConfiguraitonSettings(type: string, ownerId?: string): Promise<any> {
  let endPoint = `configs/Root/${type}`;
  if (type === "TENANT" && ownerId) {
    endPoint += `/${ownerId}`;
  }
  if (type === "ORGANIZATION" && ownerId) {
    endPoint += `/${ownerId}`;
  }
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function updateConfiguraitonSettings(data: any): Promise<any> {
  const endPoint = "configs";
  const response = await apiCall("POST", endPoint, data);
  logResponse(response);
  return response;
}
